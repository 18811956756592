import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import StorageTypes from "./StorageTypes";
import CredentialsList from "./CredentialsList";
import {storagesService} from "../services/storages";

import * as Icon from 'react-bootstrap-icons';

export function StorageForm(props) {

  const [storageType, setStorageType] = useState()
  const [credentials, setCredentials] = useState()
  const [bucketName, setBucketName] = useState('')
  const [prefix, setPrefix] = useState()
  const [errors, setErrors] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  const {selectedStorageType, selectedStorage, onClose} = props

  function onSelectedCredentials(item) {
    setCredentials(item)
  }

  function parseError(error) {
    console.log(error)

    let _errors = []
    Object.keys(error.response.data.message).forEach((key) => {
      _errors = _errors.concat(error.response.data.message[key])
    })
    setErrors(_errors)
  }

  function handleSubmit(e) {
    e.preventDefault()
    setErrors([])
    let service = null
    if (selectedStorage && selectedStorage.id) {
      service = storagesService.update(
        selectedStorage.id,
        storageType.id,
        credentials.id,
        bucketName,
        prefix
      )
    } else {
      service = storagesService.add(
        storageType.id,
        credentials.id,
        bucketName,
        prefix
      )
    }

    if (service) {
      setIsSaving(true)
      service.then((response) => {
        if (onClose) {
          onClose(response)
        }
      }).catch((error) => {
        parseError(error)
      }).finally(() => {
        setIsSaving(false)
      })
    }
  }

  function handleCancel(e) {
    e.preventDefault()
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    setStorageType(selectedStorageType)
  }, [selectedStorageType]);

  useEffect(() => {
    if (selectedStorage) {
      setStorageType(selectedStorage.storage_type)
      setCredentials(selectedStorage.credentials)
      setBucketName(selectedStorage.bucket_name)
      setPrefix(selectedStorage.prefix)
    }

  }, [selectedStorage]);

  return (
    <Container>
      {errors && <div>
        {
          errors.map((error, index) => {
            return <pre>{error}</pre>
          })
        }
      </div>
      }
      {isSaving && <div>
        <br/>
        <center>
          <pre>saving ...</pre>
        </center>
      </div>}

      {!isSaving && <div>
        {!storageType && <Alert variant={'danger'} className={'m-4'}>No storage type selected</Alert>}
        {storageType && <div>
          <br/>
          <CredentialsList
            onSelect={onSelectedCredentials}
            selectedItem={credentials}/>
          {!credentials && <Alert variant={'danger'} className={'m-4'}>
            <Row>
              <Col className="col-12 col-sm-auto">
                <Icon.Safe/>
              </Col>
              <Col>
                No credentials selected
              </Col>
            </Row>
          </Alert>}
          {credentials && <Container>
            <hr/>
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Bucket name</Form.Label>
                <Form.Control
                  value={bucketName} required type='text'
                  onChange={e => setBucketName(e.target.value)}/>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Prefix</Form.Label>
                <Form.Control
                  type='text'
                  value={prefix}
                  onChange={e => setPrefix(e.target.value)}/>
              </Form.Group>
            </Row>
          </Container>
          }
          {credentials && bucketName.length === 0 &&
          <Alert variant={'danger'} className={'m-4'}>Bucket name cannot be empty</Alert>}
        </div>
        }
        <hr/>
        <Row>
          <Col>
            {credentials && storageType && bucketName && <Button
              onClick={handleSubmit}
              variant='primary'>
              <Icon.Save/>{' '}
            </Button>
            }
          </Col>
          <Col className="col-12 col-sm-auto">
            <Button
              onClick={handleCancel}
              variant='warning'><Icon.XSquare/></Button>
          </Col>
        </Row>
      </div>}
    </Container>
  )
}

export default function Storages() {

  const [storages, setStorages] = useState([])
  const [selectedStorageType, setSelectedStorageType] = useState()
  const [selectedStorage, setSelectedStorage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  function loadExistinStorages() {
    setIsLoading(true)
    storagesService.list().then((response) => {
      setStorages(response)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadExistinStorages()
  }, []);

  function onSelectedStorageType(storageType) {
    setSelectedStorageType(storageType)
  }

  function onFormClose() {
    setSelectedStorageType(null)
    setSelectedStorage(null)
    loadExistinStorages()
  }

  function handleDeleteItem(e, item) {
    e.preventDefault()
    setIsDeleting(true)
    storagesService.del(item.id).then((response) => {
      loadExistinStorages()
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setIsDeleting(false)
    })
  }

  function handleEditItem(e, item) {
    e.preventDefault()
    setSelectedStorageType(item.storage_type)
    setSelectedStorage(item)
  }

  return (
    <Container>
      <StorageTypes
        onSelect={onSelectedStorageType}
        selectedItem={selectedStorageType}
      />

      {(selectedStorage || selectedStorageType) ? (
        <StorageForm
          selectedStorageType={selectedStorageType}
          selectedStorage={selectedStorage}
          onClose={onFormClose}
        />
      ) : (
        <Container>
          <br/>
          {isDeleting && <center>
            <pre>deleting...</pre>
          </center>}
          {isLoading && <center>
            <pre>loading storages</pre>
          </center>}
          {!isLoading && !isDeleting && <ListGroup>
            {storages.map(storage => {
              return <ListGroup.Item
                action
                key={storage.id}>
                <Row>
                  <Col className="col-12 col-sm-auto"><Icon.HddNetwork/></Col>
                  <Col>
                    {storage.storage_type.name}
                  </Col>
                  <Col className="col-12 col-sm-auto"><Icon.Safe/></Col>
                  <Col>
                    {storage.credentials.name}
                  </Col>
                  <Col className="col-12 col-sm-auto">
                    <Icon.Pen
                      onClick={(e) => handleEditItem(e, storage)}
                      color="royalblue"/>
                  </Col>
                  <Col className="col-12 col-sm-auto">
                    <Icon.Trash
                      color="red"
                      onClick={(e) => handleDeleteItem(e, storage)}/>
                  </Col>
                </Row>
              </ListGroup.Item>
            })
            }
          </ListGroup>}
          {!isLoading && !isDeleting && <Container className="m-2 p-2">
            <Row>
              <Col>
                <Icon.ArrowRepeat
                  color="royalblue"
                  size={24}
                  onClick={loadExistinStorages}/>
              </Col>
              <Col className="col-12 col-sm-auto">{storages.length || 0} item(s)</Col>
            </Row>
          </Container>
          }
        </Container>
      )
      }
    </Container>
  )
}

