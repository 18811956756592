import {useState} from "react";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useAuth} from "../App";

export default function LoginForm() {
  let history = useHistory();
  let location = useLocation();

  let {from} = location.state || {from: {pathname: "/dashboard"}};

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState()

  const auth = useAuth()

  function handleSubmit(e) {
    e.preventDefault()
    auth.login(email, password).then(() => {
      history.replace(from);
    }).catch((error) => {
      setError(error.message)
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Container className="d-flex vh-100">
        <Row className="m-auto align-self-center col-8">
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Login</Card.Title>
              </Card.Header>
              <Card.Body>
                {error &&
                <Alert variant={'danger'}>
                  <center>{error}</center>
                </Alert>
                }
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type={'email'} onChange={e => setEmail(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control type={'password'} onChange={e => setPassword(e.target.value)}/>
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Container>
                  <Row>
                    <Col>
                      <Button type={'submit'}>Login</Button>
                    </Col>
                    <Col>
                      <Link to="/register">Register</Link>
                    </Col>
                  </Row>
                </Container>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}