import { api } from "../services/services"

export const storageTypesService = {
  all
}

function all() {
    return api.get('/users/storages/types').then((response) => {
        return response.data
    })
}
