import {api} from "./services";

export const sourcesService = {
  types, list, add, del, update
}

function types() {
  return api.get('/users/sources/types').then((response) => {
    return response.data
  })
}

function list() {
  return api.get('/users/sources').then((response) => {
    return response.data
  })
}

function add(source_type_id,
             source_tasks,
             credentials_id,
             storage_id,
             destination_id, start_date, interval, enabled) {

  return api.post('/users/sources',
    {
      source_type_id,
      source_tasks,
      credentials_id,
      storage_id,
      destination_id,
      start_date, interval, enabled
    }
  ).then((response) => {
    return response.data
  })
}

function del(id) {
  return api.delete('/users/sources/' + id).then((response) => {
    return response.data
  })
}

function update(id,
                source_type_id,
                source_tasks,
                credentials_id,
                storage_id,
                destination_id,
                start_date, interval, enabled) {
  return api.put('/users/sources/' + id,
    {
      source_type_id,
      source_tasks,
      credentials_id,
      storage_id,
      destination_id,
      start_date, interval, enabled
    }).then((response) => {
    return response.data
  })
}
