import {Accordion, Alert, Button, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import SourceTypes from "./SourceTypes";
import React, {useCallback, useEffect, useState} from "react";
import CredentialsList from "./CredentialsList";
import SourceTypeTasks from "./SourceTypeTasks";
import StoragesList from "./StoragesList";
import DestinationsList from "./DestinationsList";
import * as Icon from "react-bootstrap-icons";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {sourcesService} from "../services/sources";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

export function SourceForm(props) {
  const [sourceTasks, setSourceTasks] = useState([])
  const [errors, setErrors] = useState()
  const [credentials, setCredentials] = useState()
  const [storage, setStorage] = useState()
  const [destination, setDestination] = useState()
  const [startDate, setStartDate] = useState()
  const [intervalValue, setIntervalValue] = useState()
  const [enabled, setEnabled] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const {sourceType, onClose, selectedSource} = props

  function yesterday() {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    return date.toDateString()
  }

  const resetForm = useCallback(() => {
    setSourceTasks([])
    setCredentials(null)
    setStorage(null)
    setDestination(null)
    setStartDate(yesterday())
    setIntervalValue('@daily')
    setEnabled(false)
  }, [])

  function onSelectedCredentials(item) {
    setCredentials(item)
  }

  function onSelectedStorage(item) {
    setStorage(item)
  }

  function onSelectedDestination(item) {
    setDestination(item)
  }

  function onSelectSourceTasks(tasks) {
    setSourceTasks(tasks)
  }

  function handleCancel(e) {
    e.preventDefault()
    resetForm()
    if (onClose) {
      onClose()
    }
  }

  function handleSubmit(e) {
    setIsSaving(true)
    e.preventDefault()

    let _source_tasks = []
    sourceTasks.forEach(i => {
      if (i.hasOwnProperty('label')) {
        _source_tasks.push(i.label)
      } else {
        _source_tasks.push(i)
      }
    })

    let service = null
    if (!selectedSource) {
      service = sourcesService.add(
        sourceType.id,
        _source_tasks,
        credentials.id,
        storage.id,
        destination.id,
        startDate,
        intervalValue,
        enabled
      )
    } else {
      service = sourcesService.update(
        selectedSource.id,
        sourceType.id,
        _source_tasks,
        credentials.id,
        storage.id,
        destination.id,
        startDate,
        intervalValue,
        enabled
      )
    }

    if (service) {
      service.then((response) => {
        setIsSaving(false)
        if (onClose) {
          onClose(true)
        }
      }).catch((error) => {
        console.log(error)
        setIsSaving(false)
      })
    }
  }

  function handleDelete(e) {
    e.preventDefault()
    setIsDeleting(true)
    sourcesService.del(selectedSource.id).then(() => {
      setIsDeleting(false)
      if (onClose) {
        onClose(true)
      }
    }).catch((error) => {
      console.log(error)
      setIsDeleting(false)
    })
  }

  useEffect(() => {
    resetForm()
  }, [resetForm]);

  useEffect(() => {
    if (selectedSource) {
      setIsSaving(false)
      setCredentials(selectedSource.credentials)
      setStorage(selectedSource.storage)
      setDestination(selectedSource.destination)
      setSourceTasks(selectedSource.source_tasks)
      setStartDate(selectedSource.start_date)
      setIntervalValue(selectedSource.interval)
      setEnabled(selectedSource.enabled)
    }
  }, [sourceType, selectedSource]);
  return (
    <Container>
      <br/>
      {isDeleting && <center>
        <pre>deleting ...</pre>
      </center>}
      {isSaving && <center>
        <pre>saving ...</pre>
      </center>}
      {!isSaving && !isDeleting && <Form onSubmit={handleSubmit}>
        {errors && <div>
          {
            errors.map((error) => {
              return <pre>{error}</pre>
            })
          }
        </div>}
        {(!sourceType) && <div>
          <center>
            <pre>no source type selected</pre>
          </center>
        </div>}
        {(sourceType) && <div>
          <CredentialsList
            onSelect={onSelectedCredentials}
            selectedItem={credentials}/>
          {!credentials && <Alert variant={'danger'} className={'m-4'}>
            <Row>
              <Col className="col-12 col-sm-auto">
                <Icon.Safe/>
              </Col>
              <Col>
                No credentials selected
              </Col>
            </Row>
          </Alert>}
          {credentials && <div>
            <br/>
            <SourceTypeTasks
              sourceType={sourceType || selectedSource.source_type}
              credentials={credentials}
              selectedTasks={sourceTasks}
              onSelect={onSelectSourceTasks}
            />

            <br/>
            <StoragesList
              onSelect={onSelectedStorage}
              selectedItem={storage}/>

            <br/>
            <DestinationsList
              onSelect={onSelectedDestination}
              selectedItem={destination}/>

            <br/>
            {credentials && storage && destination && <Container>
              <Row className={'d-flex'}>
                <Col>
                  <FloatingLabel
                    label={'Start date'}>
                    <Form.Control
                      type={'text'}
                      disabled={true}
                      value={startDate}/>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    label={'Interval'}>
                    <Form.Control
                      type={'text'}
                      disabled={true}
                      value={intervalValue}/>
                  </FloatingLabel>
                </Col>
                <Col>
                  <div className={'form-floating'}>
                    <div className={'form-control'}>
                      <div className={'col-12col-sm-auto float-end'}>
                        <BootstrapSwitchButton
                          checked={enabled}
                          onstyle='success'
                          offstyle='light'
                          size={'xs'}
                          onChange={(checked: boolean) => {
                            setEnabled(!enabled)
                          }}
                        />
                      </div>
                    </div>
                    <label>Enabled</label>
                  </div>
                </Col>
              </Row>
            </Container>}
            {!storage && <Alert variant={'danger'} className={'m-4'}>
              <Row>
                <Col className="col-12 col-sm-auto">
                  <Icon.HddNetwork/>
                </Col>
                <Col>
                  No Storage selected.
                </Col>
              </Row>
            </Alert>}

            {!destination && <Alert variant={'danger'} className={'m-4'}>
              <Row>
                <Col className="col-12 col-sm-auto">
                  <Icon.Layers/>
                </Col>
                <Col>
                  No destination selected.
                </Col>
              </Row>
            </Alert>}
          </div>
          }
        </div>}
        <hr/>
        <Row>
          <Col>
            {credentials && storage && destination && <Button
              type='submit'
              variant='primary'>
              <Icon.Save/>
            </Button>}
          </Col>
          {selectedSource && <Col>
            <Button
              onClick={handleDelete}
              variant='danger'><Icon.XLg/></Button>
          </Col>}
          <Col className="col-12 col-sm-auto">
            <Button
              onClick={handleCancel}
              variant='warning'><Icon.XSquare/></Button>
          </Col>
        </Row>
      </Form>}
      <br/>
    </Container>
  )
}

export default function Sources() {

  const [sources, setSources] = useState()
  const [sourceType, setSourceType] = useState()
  const [selectedSource, setSelectedSource] = useState()

  const [isLoading, setIsLoading] = useState(false)

  function loadExistingSources() {
    setIsLoading(true)
    sourcesService.list().then(function (response) {
      setSources(response)
      setIsLoading(false)
    })
  }

  function onSelectSourceType(item) {
    if (!selectedSource) {
      setSourceType(item)
    }
  }

  function onFormClose(refresh = false) {
    setSourceType(null)
    setSelectedSource(null)
    if (refresh) {
      loadExistingSources()
    }
  }

  function handleSourceSelected(item) {
    setSourceType(item.source_type)
    setSelectedSource(item)
  }

  useEffect(() => {
    loadExistingSources()
  }, []);
  return (
    <Container>
      <SourceTypes
        onSelect={onSelectSourceType}
        selectedItem={sourceType}/>

      {(selectedSource || sourceType) && <SourceForm
        sourceType={sourceType}
        onClose={onFormClose}
        selectedSource={selectedSource}
      />}

      {!sourceType && !selectedSource && <Container>
        <br/>
        {isLoading ? (
          <center>
            <pre>loading sources</pre>
          </center>
        ) : (
          <Accordion>
            {sources &&
            sources.map(function (source, index) {
              return <Accordion.Item
                eventKey={index}
                key={index}>
                <Accordion.Header>
                  <Col
                    className={'col-3'}><Icon.Diagram2/>{' '}{source.source_type.name} ({source.source_tasks.length} tasks)</Col>
                  <Col className={'col-md-4'}><Icon.HddNetwork/>{' '}{source.storage.storage_type.name}</Col>
                  <Col className={'col-md-3'}><Icon.Layers/>{' '}{source.destination.destination_type.name}</Col>
                  <Col>
                    <BootstrapSwitchButton
                      checked={source.enabled}
                      onstyle='success'
                      offstyle='light'
                      size={'xs'}
                      disabled
                    />
                  </Col>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <FloatingLabel
                        label={'Start date'}>
                        <Form.Control
                          type={'text'}
                          disabled={true}
                          value={source.start_date}/>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel
                        label={'Interval'}>
                        <Form.Control
                          type={'text'}
                          disabled={true}
                          value={source.interval}/>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <div className={'form-floating'}>
                        <div className={'form-control'}>
                          <div className={'col-12col-sm-auto float-end'}>
                            <BootstrapSwitchButton
                              checked={source.enabled}
                              onstyle='success'
                              offstyle='light'
                              size={'xs'}
                              disabled
                            />
                          </div>
                        </div>
                        <label>Enabled</label>
                      </div>
                    </Col>
                    <Col className={'col-sm-auto'}>
                      <Icon.Gear
                        role={'button'}
                        color={'royalblue'}
                        onClick={() => handleSourceSelected(source)}/>
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col>
                      {
                        source.source_tasks.map(function (item, idx) {
                          return <div
                            key={idx + item}
                            className={'btn-primary border-primary rounded m-1 p-1 d-inline-block'}>{item}</div>
                        })
                      }
                    </Col>
                  </Row>
                  <hr/>
                  <ListGroup>
                    <ListGroup.Item key={'source_type'}>
                      <Row>
                        <Col className={'col-sm-auto'}><Icon.Diagram2/></Col>
                        <Col>{source.source_type.name}</Col>
                        <Col className={'col-sm-auto'}><Icon.Safe/></Col>
                        <Col>{source.credentials.name}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col className={'col-sm-auto'}><Icon.HddNetwork/></Col>
                        <Col>{source.storage.storage_type.name}</Col>
                        <Col className={'col-sm-auto'}><Icon.Safe/></Col>
                        <Col>{source.storage.credentials.name}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col className={'col-sm-auto'}><Icon.Layers/></Col>
                        <Col>{source.destination.destination_type.name}</Col>
                        <Col className={'col-sm-auto'}><Icon.Safe/></Col>
                        <Col>{source.destination.credentials.name}</Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            })
            }
          </Accordion>
        )}
        <br/>
      </Container>}
    </Container>
  )
}