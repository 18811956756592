import {Container, ListGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {destinationService} from "../services/destinations";
import * as Icon from 'react-bootstrap-icons';


export default function DestinationTypes(props) {

  const [destinationsTypes, setDestinationsTypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const {onSelect, selectedItem} = props

  function loadDestinationTypes() {
    setDestinationsTypes([])
    setIsLoading(true)
    destinationService.types().then((response) => {
      setDestinationsTypes(response)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function handleSelectDestinationType(item) {
    if (onSelect) {
      onSelect(item)
    }
  }

  useEffect(() => {
    loadDestinationTypes()
  }, []);

  return (
    <Container>
      {isLoading ? (
        <center><pre>loading destination types</pre></center>
      ):(
        <ListGroup horizontal>
          {
            destinationsTypes.map((item) => {
              return (
                <ListGroup.Item
                  active={(selectedItem && selectedItem.id === item.id)}
                  key={item.id}
                  onClick={() => handleSelectDestinationType(item)}
                  value={item}
                  action={true}>
                  <center>
                    <Icon.Layers/>{' '}{item.name}
                  </center>
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      )}
    </Container>
  )
}