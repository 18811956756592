import {api} from "./services"

export const sourceTypeTasksService = {
  get
}

function get(source_id, credentials) {
  return api.get('/users/sources/' + source_id + '/tasks').then((response) => {
    return response.data
  })
}
