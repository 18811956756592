import {Col, Container, ListGroup, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {credentialsService} from "../services/credentials";
import * as Icon from 'react-bootstrap-icons';
import {CredentialsForm} from "./CredentialsVault";


export default function CredentialsList(props) {
  const [credentials, setCredentials] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const {onSelect, selectedItem} = props

  function loadCredentials() {
    setIsLoading(true)
    credentialsService.all().then((response) => {
      setCredentials(response.data)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadCredentials()
  }, []);

  function handleSelectCredentials(e, item) {
    e.preventDefault()
    if (onSelect) {
      onSelect(item !== selectedItem ? item : null)
    }
  }

  function handleNewCredentials(item) {
    if (item) {
      setCredentials(credentials.concat(item))
      if (onSelect) {
        onSelect(item !== selectedItem ? item : selectedItem)
      }
    }
    setShowForm(false)
    loadCredentials()
  }

  return (
    isLoading ? (
      <center>
        <pre>loading credentials</pre>
      </center>
    ) : (
      <Container>
        <Row>
          <Col className="col-12 col-sm-auto">
            <Icon.PlusSquare
              color={'royalblue'}
              role={'button'}
              onClick={() => setShowForm(true)}/>
          </Col>
          <Col><code>Credentials</code></Col>
        </Row>
        <hr/>
        {showForm ? (
          <CredentialsForm
            onClose={handleNewCredentials}/>
        ) : (
          credentials.length ? (
            <Row>
              <Col>
                <ListGroup>
                  {credentials.map((item, index) => {
                    return <ListGroup.Item
                      active={(selectedItem && selectedItem.id === item.id)}
                      key={index}
                      role={'button'}
                      onClick={(e) => handleSelectCredentials(e, item)}>
                      <Row>
                        <Col className="col-12 col-sm-auto">
                          <Icon.Safe/>
                        </Col>
                        <Col>
                          {item.name}
                        </Col>
                        <Col className="col-12 col-sm-auto">
                          {selectedItem && selectedItem.id === item.id && <Icon.Check/>}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  })}
                </ListGroup>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <center>
                  <pre>No credentials found</pre>
                </center>
              </Col>
            </Row>
          )
        )}
      </Container>
    )
  )
}