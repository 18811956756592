import {Container, ListGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import * as Icon from 'react-bootstrap-icons';
import {sourcesService} from "../services/sources";

export default function SourceTypes(props) {

  const [sourceTypes, setSourceTypes] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const {onSelect, selectedItem} = props

  function loadSourceTypes() {
    setSourceTypes([])
    sourcesService.types().then((response) => {
      setSourceTypes(response)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {setIsLoading(false)})
  }

  useEffect(() => {
    loadSourceTypes()
  }, []);

  function handleSelectSourceType(e, item) {
    e.preventDefault()
    if (onSelect) {
      return onSelect(selectedItem && selectedItem.id === item.id ? null:item)
    }
  }

  return (
    <Container>
      {isLoading ? (
        <center>
          <pre>loading source types</pre>
        </center>
      ):(
        <ListGroup horizontal>
          {sourceTypes.map((item) => {
              return <ListGroup.Item
                active={(selectedItem && selectedItem.id === item.id)}
                key={item.id}
                action={true}
                onClick={(e) => handleSelectSourceType(e, item)}>
                <Icon.Diagram2/>{' '}{item.name}
              </ListGroup.Item>
            }
          )}
        </ListGroup>
      )}
    </Container>
  )
}