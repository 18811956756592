import axios from "axios"
import { authService } from "./auth";


const api = axios.create({
  baseURL: '/api/'
});

const requestHandler = (request) => {
  const access_token = authService.getAccessToken()
  if (access_token !== null) {
    request.headers['Authorization'] = `Bearer ${access_token}`
  }
  request.headers['Content-Type'] = 'application/json'

  return request
}

api.interceptors.request.use(
  request => requestHandler(request)
)

api.interceptors.response.use(
  response => { return response },
  error => {
    const { response } = error

    if (response.status === 401) {
      window.location = '/login'
    }

    return Promise.reject(error);
  }
)
export { api }