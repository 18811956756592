import { api } from "../services/services"

export const credentialsService = {
  all, add, del, view
}

async function all() {
  return api.get('/users/credentials')
    .then((response) => {
      return response
    });
}

async function del(id) {
  return api.delete('/users/credentials/' + id)
    .then((response) => {
      return response
    });
}

async function view(id) {
  return api.get('/users/credentials/' + id + '/view')
    .then((response) => {
      return response
    });
}

async function add(name, value) {
  return api.post('/users/credentials', { name, value })
    .then((response) => {
      return response
    });
}

