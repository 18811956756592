import {Col, Container, ListGroup, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {apiKeysService} from "../services/api_keys";
import * as Icon from "react-bootstrap-icons";

export default function ApiKeysList() {
  const [apiKeys, setApiKeys] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  function loadApiKeys() {
    setIsLoading(true)
    apiKeysService.list().then((response) => {
      setApiKeys(response)
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadApiKeys()
  }, []);

  return (
    isLoading ? (
      <center>
        <pre>loading api keys </pre>
      </center>
    ) : (
      <Container>

        <Row>
          <Col className="col-12 col-sm-auto">
            <Icon.Key
              color={'royalblue'}
              role={'button'}/>
          </Col>
          <Col><code>Api Key(s)</code></Col>
        </Row>
        <hr/>
        <ListGroup>
          {apiKeys.map(function (item, index) {
            return <ListGroup.Item
              key={item.key}
              action>
              {item.key}
            </ListGroup.Item>
          })}
        </ListGroup>
      </Container>
    )
  )
}