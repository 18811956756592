import { api } from "../services/services"

export const storagesService = {
    list, add, del, update
}

function list() {
    return api.get('/users/storages').then((response) => {
        return response.data
    })
}
function add(storage_type_id, credentials_id, bucket_name, prefix) {
    return api.post('/users/storages',
        { storage_type_id, credentials_id, bucket_name, prefix })
        .then((response) => {
            return response.data
        })
}

function del(id) {
    return api.delete('/users/storages/' + id).then((response) => {
        return response.data
    })
}

function update(id, storage_type_id, credentials_id, bucket_name, prefix) {
    return api.put('/users/storages/' + id,
        { storage_type_id, credentials_id, bucket_name, prefix }
    ).then((response) => {
        return response.data
    })
}