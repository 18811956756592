import {Card, Container} from "react-bootstrap";
import CredentialsList from "./CredentialsList";
import StoragesList from "./StoragesList";
import DestinationsList from "./DestinationsList";
import ApiKeysList from "./ApiKeysList";
import SourcesList from "./SourcesList";

export default function Dashboard() {
  return (
    <Container>
      <Card>
        <Card.Header>Transformations</Card.Header>
        <Card.Body>** TO DO **</Card.Body>
      </Card>

      <br />
      <SourcesList />
      <br />
      <CredentialsList/>

      <br />
      <StoragesList/>

      <br />
      <DestinationsList/>

      <br />
      <ApiKeysList />

      <br />
    </Container>
  )
}