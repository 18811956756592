import {credentialsService} from "../services/credentials";
import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Container, Form, ListGroup, Modal, Row} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

export function CredentialsForm(props) {
  const [errors, setErrors] = useState([])
  const [name, setName] = useState()
  const [value, setValue] = useState()

  const {onClose} = props

  function handleSubmit(e) {
    e.preventDefault()
    setErrors([])
    credentialsService.add(name, value)
      .then((response) => {
        if (onClose) {
          onClose(response.data)
        }
      })
      .catch((error) => {
        let _errors = []
        Object.keys(error.response.data.message).forEach(function (key, index) {
          _errors = _errors.concat(error.response.data.message[key])
        })
        setErrors(_errors)
      })
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {errors.map((msg, index) => {
          return <AlertDismissible
            show={true}
            key={index}
            variant={'danger'}>
            <center>{msg}</center>
          </AlertDismissible>
        })
        }
        <code>Add credentials</code>
        <hr/>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type={'text'} onChange={e => setName(e.target.value)}/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Value</Form.Label>
          <Form.Control as={'textarea'} rows={4} onChange={e => setValue(e.target.value)}/>
        </Form.Group>
        <hr/>
        <Form.Group className={'float-right'}>
          <Row>
            <Col className="ml-auto">
              <Button
                onClick={handleSubmit}
                variant={'primary'}>
                <Icon.PlusSquare/>
              </Button>
            </Col>
            <Col className="col-12 col-sm-auto">
              <Button
                onClick={() => onClose(null)}
                variant={'warning'}>
                <Icon.XSquare/>
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </Container>
  )
}

const DeleteConfirmation = ({showModal, hideModal, confirmModal, item}) => {
  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {item ? (
          <Alert variant="danger">Are you sure you want to delete <b>{item.name}</b> credentials?</Alert>
        ) : null
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => confirmModal(item)}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const ViewItem = ({callback, item}) => {

  function hideMe() {
    callback()
  }

  return (
    <Modal show={item !== null} onHide={hideMe}>

      <Modal.Header>
        <Modal.Title>{item ? item.name : null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>
          <code>{item ? item.value : '{}'}</code>
        </pre>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideMe}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function AlertDismissible({children, ...props}) {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false)
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Alert
      {...props}
      show={show}
      onClose={() => setShow(false)}
      onClick={() => setShow(false)}
    >
      {children}
    </Alert>
  )
}

export default function CredentialsVault() {
  const [credentials, setCredentials] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [errors, setErrors] = useState([])
  const [previewItem, setPreviewItem] = useState(null)

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

  function showAddForm(e) {
    e.preventDefault()
    setShowForm(!showForm)
  }

  function loadExistingCredentials() {
    setIsLoading(true)
    setShowForm(false)
    setErrors([])
    credentialsService.all().then((response) => {
      setCredentials(response.data)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadExistingCredentials();
  }, []);

  function handleLoad(e) {
    e.preventDefault()
    return loadExistingCredentials()
  }

  function deleteItem(item) {
    setSelectedItem(item)
    setDisplayConfirmationModal(true)
  }

  function handleFormClose(item) {
    setShowForm(false)
    loadExistingCredentials()
  }

  function parseError(error) {
    setErrors([])
    let _errors = []
    console.log(error)
    if (error) {
      Object.keys(error.response.data.message).forEach(function (key) {
        _errors = _errors.concat(error.response.data.message[key])
      })
      setErrors(_errors)
    }
  }

  function submitDelete(item) {
    credentialsService.del(item.id)
      .catch((error) => {
        parseError(error)
      }).finally(() => {
      loadExistingCredentials()
      setSelectedItem(null)
      setDisplayConfirmationModal(false);
      setShowForm(false)
    })
  }

  function hideConfirmationModal() {
    setDisplayConfirmationModal(false);
  }

  function viewItem(item) {
    credentialsService.view(item.id).then((response) => {
      setPreviewItem(response.data)
    }).catch((error) => {
      parseError(error)
    })

  }

  return (
    <Container>
      {!isLoading && <Container>
        {
          showForm || !credentials.length ? (
            <Row as={CredentialsForm}
                 onClose={handleFormClose}/>
          ) : (
            <Container className="m-2 p-2">
              {errors.map((error, index) => {
                return <AlertDismissible
                  variant={'danger'}>
                  <center>{error}</center>
                </AlertDismissible>
              })
              }
              <ListGroup>
                {credentials.map((item, index) => {
                  return <ListGroup.Item action key={index}>
                    <Row>
                      <Col className="col-12 col-sm-auto">
                        <Icon.Safe
                          color="royalblue"
                          onClick={() => deleteItem(item)}/>
                      </Col>
                      <Col>{item.name}</Col>
                      <Col className="col-12 col-sm-auto">
                        <Icon.ZoomIn
                          color="royalblue"
                          onClick={() => viewItem(item)}/>
                      </Col>
                      <Col className="col-12 col-sm-auto">
                        <Icon.Trash
                          color="red"
                          onClick={() => deleteItem(item)}/>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                })}
              </ListGroup>
            </Container>
          )
        }
      </Container>
      }
      {isLoading && <center>
        <pre>loading credentials</pre>
      </center>}
      {!isLoading && credentials.length > 0 && <Container className="m-2 p-2">
        <Row>
          <Col className="col-12 col-sm-auto">
            <Icon.FolderPlus
              color="royalblue"
              size={24}
              onClick={showAddForm}/>
          </Col>
          <Col>
            <Icon.ArrowRepeat
              color="royalblue"
              size={24}
              onClick={handleLoad}
            />
          </Col>
          <Col className="col-12 col-sm-auto">{credentials.length || 0} item(s)</Col>
        </Row>
      </Container>
      }
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        item={selectedItem}/>
      <ViewItem
        callback={() => setPreviewItem(null)}
        item={previewItem}
      />
    </Container>
  )
}