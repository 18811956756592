import {Col, Container, ListGroup, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import * as Icon from 'react-bootstrap-icons';
import {storagesService} from "../services/storages";
import {StorageForm} from "./Storages";
import StorageTypes from "./StorageTypes";


export default function StoragesList(props) {
  const [storages, setStorages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [selectedStorageType, setSelectedStorageType] = useState()

  const {onSelect, selectedItem} = props

  function loadStorages() {
    setIsLoading(true)
    storagesService.list().then((response) => {
      setStorages(response)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadStorages()
  }, []);

  function handleSelectStorages(e, item) {
    e.preventDefault()
    if (onSelect) {
      onSelect(item !== selectedItem ? item : null)
    }
  }

  function handleNewStorage(item) {
    if (item) {
      setStorages(storages.concat(item))
      if (onSelect) {
        onSelect(item !== selectedItem ? item : selectedItem)
      }
    }
    setShowForm(false)
    loadStorages()
  }

  function onSelectedStorageType(item) {
    setSelectedStorageType(item)
  }

  return (
    isLoading ? (
      <center>
        <pre>loading storages</pre>
      </center>
    ) : (
      <Container>
        <Row>
          <Col className="col-12 col-sm-auto">
            <Icon.PlusSquare
              color={'royalblue'}
              onClick={() => setShowForm(true)}/>
          </Col>
          <Col><code>Storages</code></Col>
        </Row>
        <hr/>
        {showForm ? (
          <div>
            <StorageTypes
              onSelect={onSelectedStorageType}
              selectedItem={selectedStorageType}
            />
            <StorageForm
              onClose={handleNewStorage}
              selectedStorageType={selectedStorageType}
            />
          </div>
        ) : (storages.length ? (
            <Row>
              <Col>
                <ListGroup>
                  {storages && storages.map((item, index) => {
                    return <ListGroup.Item
                      active={(selectedItem && selectedItem.id === item.id)}
                      key={index}
                      role={'button'}
                      onClick={(e) => handleSelectStorages(e, item)}>
                      <Row>
                        <Col className="col-12 col-sm-auto">
                          <Icon.HddNetwork/>
                        </Col>
                        <Col>
                          {item.storage_type.name}
                        </Col>
                        <Col className="col-12 col-sm-auto"><Icon.Safe/></Col>
                        <Col>
                          {item.credentials.name}
                        </Col>
                        <Col className="col-12 col-sm-auto">
                          {selectedItem && selectedItem.id === item.id && <Icon.Check/>}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  })}
                </ListGroup>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <center>
                  <pre>No storages found</pre>
                </center>
              </Col>
            </Row>
          )
        )}
      </Container>
    )
  )
}