import {Col, Container, Row} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {sourceTypeTasksService} from "../services/sources_types_tasks";
import * as Icon from 'react-bootstrap-icons';


export default function SourceTypeTasks(props) {

  const [sourceTasks, setSourceTasks] = useState([])
  const [selectedSourceTasks, setSelectedSourceTasks] = useState([])

  const {sourceType, credentials, selectedTasks, onSelect} = props

  const loadSourceTypeTasks = useCallback(() => {
    sourceTypeTasksService.get(sourceType.id, credentials).then((response) => {
      setSourceTasks(response)
      if (onSelect && (!selectedTasks || !selectedTasks.length)) {
        onSelect(response)
      }
    })
  })

  function isSelected(item) {
    for (let i = 0; i < selectedSourceTasks.length; i++) {
      if (selectedSourceTasks[i].id === item.id) {
        return true
      }
    }
    return false
  }

  function removeItem(item) {
    let _selected = []
    selectedSourceTasks.forEach(function (task) {
      if (task.id !== item.id) {
        _selected.push(task)
      }
    })
    setSelectedSourceTasks(_selected)
  }

  function addItem(item) {
    setSelectedSourceTasks([...selectedSourceTasks, item])
  }

  function handleClick(e, item) {
    e.preventDefault()
    if (isSelected(item)) {
      removeItem(item)
    } else {
      addItem(item)
    }
  }

  // const setSelectedTasks = useCallback(() => {
  //   let selected = sourceTasks
  //   if (selectedTasks) {
  //     selected = []
  //     selectedTasks.forEach(function (selectedTask) {
  //       if (selectedTask.hasOwnProperty('id')) {
  //         selected.push(selectedTask)
  //       } else {
  //         sourceTasks.forEach(function (task) {
  //           if (selectedTask === task['label']) {
  //             selected.push(task)
  //           }
  //         })
  //       }
  //     })
  //   }
  //   setSelectedSourceTasks(selected)
  // })

  // useEffect(() => {
  //   if (onSelect) {
  //     onSelect(selectedSourceTasks)
  //   }
  // }, [selectedSourceTasks, onSelect]);

  useEffect(() => {
    loadSourceTypeTasks()
  }, [loadSourceTypeTasks, sourceType]);


  function invertSelected(e) {
    e.preventDefault()
    let _selected = []
    sourceTasks.forEach(task => {
      if (!isSelected(task)) {
        _selected.push(task)
      }
    })
    setSelectedSourceTasks(_selected)
  }

  return (

    <div>
      {sourceTasks && <Container>
        <Row>
          <Col className="col-12 col-sm-auto">
            <Icon.CheckAll
              role={'button'}
              onClick={invertSelected}
              color={'royalblue'}
            />
          </Col>
          <Col><code>{sourceType.name} Tasks</code></Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            <div>
              {selectedSourceTasks.map((task, i) => {
                return isSelected(task) ? (
                  <div className={'d-inline-block m-1 bg-primary btn rounded border-primary text-white'}
                       key={(task.label + task.id || task) + i}
                       onClick={(e) => handleClick(e, task)}>
                    <Row>
                      <Col>
                        {task['name'] || task['label'] || task}
                      </Col>
                      <Col className="col-12 col-sm-auto">
                        <Icon.CheckSquare/>
                      </Col>
                    </Row>
                  </div>
                ) : null
              })}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div>
              {sourceTasks.map((task, i) => {
                return !isSelected(task) ? (
                  <div className={'d-inline-block m-1 bg-light btn rounded border-light text-dark'}
                       key={task.id + task.label || task}
                       onClick={(e) => handleClick(e, task)}>
                    <Row>
                      <Col>
                        {task['name'] || task['label']}
                      </Col>
                      <Col className="col-12 col-sm-auto">
                        <Icon.Square/>
                      </Col>
                    </Row>
                  </div>
                ) : null
              })}
            </div>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}