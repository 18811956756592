import React, {useEffect, useState, useCallback} from "react";
import {Alert, Button, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import DestinationTypes from "./DestinationTypes";
import CredentialsList from "./CredentialsList";
import * as Icon from 'react-bootstrap-icons';
import {destinationService} from "../services/destinations";

export function DestinationForm(props) {

  const [destinationType, setDestinationType] = useState()
  const [credentials, setCredentials] = useState()
  const [schemaPrefix, setSchemaPrefix] = useState()
  const [tablePrefix, setTablePrefix] = useState()
  const [errors, setErrors] = useState()
  const {selectedDestinationType, selectedDestination, onClose} = props

  function onSelectedCredentials(item) {
    setCredentials(item)
  }

  function handleCancel(e) {
    e.preventDefault()
    if (onClose) {
      onClose()
    }
  }

  function parseError(error) {
    setErrors([])
    let _errors = []
    Object.keys(error.response.data.message).forEach((key) => {
      _errors = _errors.concat(error.response.data.message[key])
    })
    setErrors(_errors)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (selectedDestination && selectedDestination.id) {
      destinationService.update(
        selectedDestination.id,
        destinationType.id,
        credentials.id,
        schemaPrefix,
        tablePrefix
      ).then(() => {
        if (onClose) {
          onClose()
        }
      }).catch((error) => {
        parseError(error)
      })
    } else {
      destinationService.add(
        destinationType.id,
        credentials.id,
        schemaPrefix,
        tablePrefix).then(() => {
        if (onClose) {
          onClose()
        }
      }).catch((error) => {
        parseError(error)
      })
    }
  }
  useEffect(() => {
    if (selectedDestinationType) {
      setDestinationType(selectedDestinationType)
    }
  }, [selectedDestinationType]);

  useEffect(() => {
    if (selectedDestination) {
      setDestinationType(selectedDestination.destination_type)
      setCredentials(selectedDestination.credentials)
      setSchemaPrefix(selectedDestination.schema_prefix)
      setTablePrefix(selectedDestination.table_prefix)
    }
  }, [selectedDestination]);

  return (
    <Container>
      {errors && <div>
        {
          errors.map((error) => {
            return <pre>{error}</pre>
          })
        }
      </div>}

      {destinationType && <div>
        <br/>
        <CredentialsList
          onSelect={onSelectedCredentials}
          selectedItem={credentials}/>
        {!credentials && <Alert variant={'danger'} className={'m-4'}>
          <Row>
            <Col className="col-12 col-sm-auto">
              <Icon.Safe/>
            </Col>
            <Col>
              No credentials selected
            </Col>
          </Row>
        </Alert>}
        {credentials && <Container>
          <hr/>
          <Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Schema prefix</Form.Label>
              <Form.Control
                value={schemaPrefix || 'raw_'}
                type="text"
                onChange={e => setSchemaPrefix(e.target.value)}/>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Table prefix</Form.Label>
              <Form.Control
                value={tablePrefix || ''}
                type="text"
                onChange={e => setTablePrefix(e.target.value)}/>
            </Form.Group>
          </Row>
        </Container>}
      </div>
      }
      <hr/>
      <Row>
        <Col>
          {destinationType && credentials && <Button
            onClick={handleSubmit}
            variant="primary">
            <Icon.Save/>
          </Button>}
        </Col>

        <Col className="col-12 col-sm-auto">
          <Button
            onClick={handleCancel}
            variant='warning'>
            <Icon.XSquare/>
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default function Destinations() {

  const [selectedDestinationType, setSelectedDestinationType] = useState()
  const [selectedDestination, setSelectedDestination] = useState()
  const [destinations, setDestinations] = useState([])
  const [isLoading, setIsLoading] = useState()
  const [isDeleting, setIsDeleting] = useState(false)

  function onSelectedDestinationType(item) {
    setSelectedDestinationType(item)
  }

  const loadExistingDestinations = useCallback(() =>  {
    setIsLoading(true)
    destinationService.list().then((response) => {
      setDestinations(response)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  function onFormClose() {
    setSelectedDestinationType(null)
    setSelectedDestination(null)
    loadExistingDestinations()
  }

  useEffect(() => {
    loadExistingDestinations()
  }, [loadExistingDestinations]);

  function handleDeleteItem(e, item) {
    e.preventDefault()
    setIsDeleting(true)
    destinationService.del(item.id).then(() => {
      loadExistingDestinations()
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setIsDeleting(false)
    })
  }

  function handleEditItem(e, item) {
    e.preventDefault()
    setSelectedDestinationType(item.destination_type)
    setSelectedDestination(item)
  }

  return (
    <Container>
      <DestinationTypes
        onSelect={onSelectedDestinationType}
        selectedItem={selectedDestinationType}
      />
      {(selectedDestination || selectedDestinationType) ? (
        <DestinationForm
          selectedDestinationType={selectedDestinationType}
          selectedDestination={selectedDestination}
          onClose={onFormClose}
        />
      ) : (
        <Container>
          <br/>
          {isDeleting && <center>
            <pre>deleting...</pre>
          </center>}
          {isLoading && <center>
            <pre>loading destinations</pre>
          </center>}
          {!isLoading && !isDeleting && <div>
            <ListGroup>
              {destinations.map((destination) => {
                return <ListGroup.Item
                  action
                  key={destination.id}>
                  <Row>
                    <Col className="col-12 col-sm-auto"><Icon.Layers/></Col>
                    <Col>
                      {destination.destination_type.name}
                    </Col>
                    <Col className="col-12 col-sm-auto"><Icon.Safe /></Col>
                    <Col>
                      {destination.credentials.name}
                    </Col>
                    <Col className="col-12 col-sm-auto">
                      <Icon.Pen
                        onClick={(e) => handleEditItem(e, destination)}
                        color="royalblue"/>
                    </Col>
                    <Col className="col-12 col-sm-auto">
                      <Icon.Trash
                        onClick={(e) => handleDeleteItem(e, destination)}
                        color="red"/>
                    </Col>
                  </Row>
                </ListGroup.Item>
              })}
            </ListGroup>
            <Container className="m-2 p-2">
              <Row>
                <Col>
                  <Icon.ArrowRepeat
                    color="royalblue"
                    size={24}
                    onClick={loadExistingDestinations}/>
                </Col>
                <Col className="col-12 col-sm-auto">{destinations.length || 0} item(s)</Col>
              </Row>
            </Container>
          </div>}
        </Container>
      )}

    </Container>
  )
}