import React, {useEffect, useState} from "react";
import {Container, ListGroup} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

import {storageTypesService} from "../services/storage_types";

export default function StorageTypes(props) {

  const {onSelect, selectedItem} = props

  const [storageTypes, setStorageTypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  function loadStorageTypes() {
    setStorageTypes([])
    setIsLoading(true)
    storageTypesService.all().then((response) => {
      setStorageTypes(response)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadStorageTypes()
  }, []);

  function handleSelectStorageType(e, storageType) {
    e.preventDefault()
    if (onSelect) {
      onSelect(storageType)
    }
  }

  return (
    <Container>
      {isLoading ? (
        <center>
          <pre>loading storages types</pre>
        </center>
      ) : (
        <ListGroup horizontal>
          {
            storageTypes.map((item) => {
              return (
                <ListGroup.Item
                  active={(selectedItem && selectedItem.id === item.id)}
                  key={item.id}
                  onClick={(e) => handleSelectStorageType(e, item)}
                  value={item}
                  action={true}>
                  <Icon.HddNetwork/>{' '}{item.name}
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      )}
    </Container>
  )
}