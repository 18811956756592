import { api } from "./services"

export const apiKeysService = {
  list
}

function list() {
  return api.get('/users/api_keys').then((response) => {
    return response.data
  })
}
