import './App.css';
import React, {createContext, useContext, useState} from "react";
import Dashboard from "./components/Dashboard";
import LoginForm from "./components/LoginForm";
import RegistrationForm from "./components/RegistrationForm"
import {authService} from './services/auth';
import {Link, Redirect, Route, Switch} from "react-router-dom";
import CredentialsVault from './components/CredentialsVault';
import {Col, Container, ListGroup, Nav, Navbar, Row} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Storages from './components/Storages';
import Destinations from './components/Destinations';
import Sources from './components/Sources';
import ApiKeys from "./components/ApiKeys";
import Transformations from "./components/Transformations";

export function Page({children, ...rest}) {
  const {path, title} = rest
  const auth = useAuth()

  function PathIcon(p) {
    const {path, size, color} = p

    if (path === '/credentials') {
      return <Icon.Safe
        size={size}
        color={color}/>
    }

    if (path === '/dashboard') {
      return <Icon.ClipboardData
        size={size}
        color={color}/>
    }

    if (path === '/sources') {
      return <Icon.Diagram2
        size={size}
        color={color}/>
    }

    if (path === '/storages') {
      return <Icon.HddNetwork
        size={size}
        color={color}/>
    }

    if (path === '/transformations') {
      return <Icon.Shuffle
        size={size}
        color={color}/>
    }

    if (path === '/destinations') {
      return <Icon.Layers
        size={size}
        color={color}/>
    }

    if (path === '/api_keys') {
      return <Icon.Key
        size={size}
        color={color}/>
    }

    return <Icon.House
      size={size}
      color={color}/>

  }

  function logout(e) {
    auth.logout()
  }

  return !auth.isLoggedIn ? null : (
    <Container fluid>
      <Row>
        <Navbar expand={'lg'} bg={'dark'} variant={'dark'}>
          <Nav className="container-fluid">
            <Nav.Item>
              <Nav.Link as={Link} to="/profile">
                <Icon.PersonBoundingBox
                  color="royalblue"
                  size={24}/>
                {' '}{auth.currentUser.name}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to={path}>
                <h5><PathIcon
                  path={path}
                  size={32}
                  color="royalblue"/>{' '}{title}</h5>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-auto">
              <Nav.Link onClick={logout}>
                <Icon.XSquare
                  color="royalblue"
                  size={24}/>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar>
      </Row>
      <p/>
      <Row>
        <Col xs={3}>
          <ListGroup
            defaultActiveKey={path.replace('/', '#')}>
            <ListGroup.Item action as={Link}
                            to="/dashboard" href="#dashboard">
              <PathIcon
                path="/dashboard"
                size={16}/>{' '}Dashboard
            </ListGroup.Item>
            <ListGroup.Item action as={Link}
                            to="/transformations" href="#transformations">
              <PathIcon
                path={"/transformations"}
                size={16}/>{' '}Transformations
            </ListGroup.Item>
            <ListGroup.Item action as={Link}
                            to="/sources" href="#sources">
              <PathIcon
                path={"/sources"}
                size={16}/>{' '}Sources
            </ListGroup.Item>
            <ListGroup.Item action as={Link}
                            to="/credentials" href="#credentials">
              <PathIcon
                path={"/credentials"}
                size={16}/>{' '}Credentials Vault
            </ListGroup.Item>
            <ListGroup.Item action as={Link}
                            to="/storages" href="#storages">
              <PathIcon
                path={"/storages"}
                size={16}/>{' '}Storages
            </ListGroup.Item>
            <ListGroup.Item action as={Link}
                            to="/destinations" href="#destinations">
              <PathIcon
                path={"/destinations"}
                size={16}/>{' '}Destinations
            </ListGroup.Item>
            <ListGroup.Item action as={Link}
                            to="/api_keys" href="#api_keys">
              <PathIcon
                path={"/api_keys"}
                size={16}/>{' '}Api Keys
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>{children}</Col>
      </Row>
    </Container>
  )
}

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
};

export function ProtectedRoute({component: Component, ...rest}) {
  const auth = useAuth()

  return (
    <Route {...rest} render={props => {

      if (!auth.isLoggedIn) {
        return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
      }
      return <Page {...rest}>
        <Component {...props} />
      </Page>
    }}/>
  )
}

function useProvideAuth() {
  const [isLoggedIn, setIsLoggedIn] = useState(authService.isLoggedIn())
  const [currentUser, setCurrentUser] = useState(authService.currentUser())

  const login = async (email, password) => {
    return authService.login(email, password).then(() => {
      setIsLoggedIn(true);
      setCurrentUser(authService.currentUser());
    })
  }

  const logout = async () => {
    return authService.logout().then(() => {
      setIsLoggedIn(false);
      setCurrentUser(null);
    })
  }

  const register = async (fullname, email, password) => {
    return authService.register(fullname, email, password).then(() => {
      return login(email, password)
    })
  }

  return {
    isLoggedIn,
    currentUser,
    login,
    logout,
    register
  }
}

export function ProvideAuth({children}) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>
    {children}
  </authContext.Provider>;
}

export default function App() {

  return (
      <Switch>
        <Route path="/login" component={LoginForm}/>
        <Route path="/register" component={RegistrationForm}/>
        <Route exact path="/" render={() => (<Redirect to="/dashboard"/>)}/>

        <ProtectedRoute
          path="/dashboard"
          component={Dashboard}
          title={"Dashboard"}/>
        <ProtectedRoute
          path="/sources"
          component={Sources}
          title={"Sources"}/>
        <ProtectedRoute
          path="/transformations"
          component={Transformations}
          title={"Transformations"}/>
        <ProtectedRoute
          path="/credentials"
          component={CredentialsVault}
          title={"Credentials Vault"}/>
        <ProtectedRoute
          path="/storages"
          component={Storages}
          title={"Storages"}/>
        <ProtectedRoute
          path="/destinations"
          component={Destinations}
          title={"Destinations"}/>
        <ProtectedRoute
          path="/api_keys"
          component={ApiKeys}
          title={"Api keys"}/>
      </Switch>
  );
}
