import { api } from "./services"

export const destinationService = {
  types, list, add, del, update
}

function types() {
  return api.get('/users/destinations/types').then((response) => {
    return response.data
  })
}

function list() {
  return api.get('/users/destinations').then((response) => {
    return response.data
  })
}

function add(destination_type_id, credentials_id, schema_prefix, table_prefix) {
  return api.post('/users/destinations',
    { destination_type_id, credentials_id, schema_prefix, table_prefix }
  ).then((response) => {
    return response.data
  })
}

function del(id) {
  return api.delete('/users/destinations/' + id).then((response) => {
    return response.data
  })
}

function update(id, destination_type_id, credentials_id, schema_prefix, table_prefix) {
  return api.put('/users/destinations/' + id, 
  {id, destination_type_id, credentials_id, schema_prefix, table_prefix}
  ).then((response) => {
    return  response.data
  })
}