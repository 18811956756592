import { api } from './services';
import {check_if_is_fucking_null} from "../utils";

export const authService = {
  login,
  logout,
  register,
  currentUser,
  isLoggedIn,
  getAccessToken,
  refreshAccessToken
}

async function refreshAccessToken() {
  const refresh_token = getRefreshToken()

  const options = {
    headers: {}
  }

  if (refresh_token) {
    options.headers['Authorization'] = `Bearer ${refresh_token}`
  }

  return api.post('/token/refresh', null, options).then((response) => {
    localStorage.setItem('accessToken', JSON.stringify(response.data.access_token));
    return getAccessToken();
  }).catch((error) => {
    logout();
    return error
  })
}

function getRefreshToken() {
  const refreshToken = localStorage.getItem("refreshToken")
  if (!check_if_is_fucking_null(refreshToken)) {
    return JSON.parse(refreshToken)
  }
  return refreshToken
}

function getAccessToken() {
  const accessToken = localStorage.getItem("accessToken")
  if (!check_if_is_fucking_null(accessToken)) {
    return JSON.parse(accessToken)
  }
  return accessToken
}

function currentUser() {
  const user = localStorage.getItem("currentUser")
  if (!check_if_is_fucking_null(user)) {
    return JSON.parse(user)
  }
  return false
}

function isLoggedIn() {
  return localStorage.getItem("currentUser") !== null
}

function logout() {
  return new Promise((resolve, reject) => {
    localStorage.clear()
    resolve(true)
  })
}

async function login(email, password) {
  return await api.post('/users/login', { email, password })
    .then((response) => {
      localStorage.setItem('currentUser', JSON.stringify(response.data.user))
      localStorage.setItem('accessToken', JSON.stringify(response.data.access_token))
      localStorage.setItem('refreshToken', JSON.stringify(response.data.refresh_token))
      return response
    });
}

async function register(name, email, password) {
  return await api.post('/users/register', { name, email, password })
}
