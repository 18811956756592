import {useState} from "react";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {useAuth} from "../App";

export default function LoginForm() {
  const auth = useAuth()
  let history = useHistory();

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [errors, setErrors] = useState([])

  function handleSubmit(e) {
    e.preventDefault()
    setErrors([])

    auth.register(name, email, password).then(() => {
      history.replace("/dashboard");
    }).catch((error) => {
      let _errors = []
      Object.keys(error.response.data.message).forEach((key, index) => {
        _errors = _errors.concat(error.response.data.message[key])
      })
      setErrors(_errors)
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Container className="d-flex vh-100">
        <Row className="m-auto align-self-center col-8">
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Register</Card.Title>
              </Card.Header>
              <Card.Body>

                {errors.map((error, index) => {
                  return <Alert key={index} variant={'danger'}>
                    <center>{error}</center>
                  </Alert>
                })
                }
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type={'string'} onChange={e => setName(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type={'email'} onChange={e => setEmail(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={'password'} onChange={e => setPassword(e.target.value)}/>
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Container>
                  <Row>
                    <Col>
                      <Button type={'submit'}>Register</Button>
                    </Col>
                    <Col>
                      <Link to="/login">Login</Link>
                    </Col>
                  </Row>
                </Container>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}