import {Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import React, {useEffect, useState} from "react";
import {sourcesService} from "../services/sources";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

export default function SourcesList() {

  const [isLoading, setIsLoading] = useState(false)
  const [sources, setSources] = useState([])

  function loadExistingSources() {
    setIsLoading(true)
    sourcesService.list().then(function (response) {
      setSources(response)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadExistingSources()
  }, []);

  return (
    isLoading ? (
      <center>
        <pre>loading existing sources</pre>
      </center>
    ) : (
      <Container>
        <Row>
          <Col className="col-12 col-sm-auto">
            <Icon.Diagram2
              color={'royalblue'}
              role={'button'}/>
          </Col>
          <Col><code>Sources</code></Col>
        </Row>
        <hr/>
        {!sources.length && <Row>
          <Col>
            <center>
              <pre>No storages found</pre>
            </center>
          </Col>
        </Row>}
        {sources && <Row>
          <Col>
            <ListGroup>
              {sources.map(function (item, index) {
                let cls = item.enabled ? ' bg-primary border-primary ' : ' bg-secondary border-secondary '
                return <ListGroup.Item
                  key={item.id}>
                  <Row>
                    <Col className={'col-sm-auto'}>
                      <Icon.Diagram2/>
                    </Col>
                    <Col className={'col-sm-3'}>
                      <Row>
                        <Col className={'fw-bold'}>{item.source_type.name}</Col>
                      </Row>
                      <Row>
                        <Col className={'fw-light text-black-50'}>{item.start_date}</Col>
                      </Row>
                      <Row>
                        <Col className={'fw-light text-black-50'}>{item.interval}</Col>
                      </Row>
                      <Row>
                        <Col>
                          <BootstrapSwitchButton
                            checked={item.enabled}
                            onstyle='primary'
                            offstyle='secondary'
                            size={'xs'}
                            disabled
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      {item.source_tasks.map(function (task, task_index) {
                        return <div
                          key={'source-task' + task_index}
                          className={'d-inline-block m-1 p-2 rounded small text-white ' + cls}>
                          {task}</div>
                      })}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col className={'col-sm-auto'}><Icon.Safe/></Col>
                    <Col>{item.credentials.name}</Col>
                    <Col className={'col-sm-auto'}><Icon.HddNetwork/></Col>
                    <Col>{item.storage.storage_type.name}</Col>
                    <Col className={'col-sm-auto'}><Icon.Layers/></Col>
                    <Col>{item.destination.destination_type.name}</Col>
                  </Row>
                  <br />
                </ListGroup.Item>
              })}
            </ListGroup>
          </Col>
        </Row>}
      </Container>
    )
  )
}